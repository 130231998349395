html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  /* Ensures the page takes at least full viewport height */
}

body {
  display: flex;
  flex-direction: column;
  overflow: auto;
  /* This allows scrolling if content overflows */
  height: auto;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.bar {
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 1%, rgba(96,165,250,1) 68%); 
}

#hero {
  background-image: url('https://images.unsplash.com/photo-1678203699263-917199c725b2?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8aGVybyUyMGltYWdlfGVufDB8fDB8fHww');
  /* Replace with your image URL */
  background-size: cover;
  /* Makes the background image cover the entire div */
  background-position: center;
  /* Centers the background image */
  background-repeat: no-repeat;

}